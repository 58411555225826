import * as React from 'react';
import "../lib/carbon-components/scss/components/checkbox/_checkbox.scss";
import "../lib/carbon-components/scss/components/select/_select.scss";
import "../lib/carbon-components/scss/components/form/_form.scss";
import "../lib/carbon-components/scss/components/text-input/_text-input.scss";
import * as styles from './UIDownloadForm.module.scss';

import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Checkbox } from 'carbon-components-react';
import { Form } from 'carbon-components-react';
import { FormGroup } from 'carbon-components-react';
import { Select } from 'carbon-components-react';
import { SelectItem } from 'carbon-components-react';
import { TextInput } from 'carbon-components-react';
import { Button } from 'carbon-components-react';
import { countriesEN, countriesAR } from '../data/countries';
import { EIU_ENDPOINT } from '../utils/Constants';
import axios from 'axios';

const initialState = {
    invalidName: false,
    invalidEmail: false,
    invalidTitle: false,
    invalidCompany: false,
    invalidNationality: false,
    invalidCountry: false,
    isSaving: false,
    userInfo: [],
};
type State = Readonly<typeof initialState>;
var download_access_cookie = typeof document !== 'undefined' && document.cookie

var eiu_file = 'https://images.ctfassets.net/2h1qowfuxkq7/1LyDpDt6oYMywfxWK1abOT/28d9a3c84f83cc6c1c6a93b2965d1a67/New_Schools_of_Thought_Report.pdf';
var eiu_precision_medicine_report_file = 'https://images.ctfassets.net/2h1qowfuxkq7/3pOPJngBi8r0RDCvbylmqN/70df9d809d4553672c5ab8572363934e/Doing_better_Fulfilling_the_promise_of_precision_medicine_10_11_2020.pdf';

class DownloadForm extends React.Component<{ name: string, currLang: string } & WrappedComponentProps, State> {
    readonly state: State = initialState;
    private allCountries = this.props.currLang === 'ar-QA' ? countriesAR : countriesEN
    private apiEndpoint = EIU_ENDPOINT
    private eiuCookie = this.props.name === 'eiu' ? 'download_access_granted' : this.props.name
    private cookie = ("; "+ download_access_cookie).split("; " + this.eiuCookie + "=").pop().split(";").shift();

    getFormData() {
        var data = {};
        // @ts-ignore
        let name = document.getElementById("name").value;
        // @ts-ignore
        let email = document.getElementById("email").value;
        // @ts-ignore
        let title = document.getElementById("title").value;
        // @ts-ignore
        let company = document.getElementById("company").value;
        // @ts-ignore
        let nationality = document.getElementById("nationality");
        // @ts-ignore
        let country = document.getElementById("country");
        // @ts-ignore
        let subscribe = document.getElementById("subscribe_to_newsletter").checked;

        data = {
            "report": this.props.name,
            "name": name,
            "email": email,
            "title": title,
            "company": company,
            // @ts-ignore
            "nationality": nationality.options[nationality.selectedIndex].text,
            // @ts-ignore
            "country": country.options[country.selectedIndex].text,
            "subscribe": subscribe ? 'Yes' : 'No',
            // @ts-ignore
            "nationalityKey": nationality.value,
            // @ts-ignore
            "countryKey": country.value,
            "userInfo": JSON.stringify(this.state.userInfo)
        }

        return JSON.parse(JSON.stringify(data))
    }

    validateForm() {

        let formIsValid = true;
        const { name, email, title, company, nationalityKey, countryKey } = this.getFormData()
  
        if (!name) {
          formIsValid = false;
          this.setState({
            invalidName: true
          });
        } else {
            this.setState({
                invalidName: false
            });
        }
  
        if (!email) {
          formIsValid = false;
          this.setState({
            invalidEmail: true
          });
        } else if (email) {
          //regular expression for email invalidation
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(email)) {
            formIsValid = false;
            this.setState({
                invalidEmail: true
            });
          } else {
                this.setState({
                    invalidEmail: false
                });
            }
        } else {
            this.setState({
                invalidEmail: false
            });
        }
  
        if (!title) {
            formIsValid = false;
            this.setState({
                invalidTitle: true
            });
        } else {
            this.setState({
                invalidTitle: false
            });
        }

        if (!company) {
            formIsValid = false;
            this.setState({
                invalidCompany: true
            });
        } else {
            this.setState({
                invalidCompany: false
            });
        }

        if (nationalityKey === '0') {
            formIsValid = false;
            this.setState({
                invalidNationality: true
            });
        } else {
            this.setState({
                invalidNationality: false
            });
        }

        if (countryKey === '0') {
            formIsValid = false;
            this.setState({
                invalidCountry: true
            });
        } else {
            this.setState({
                invalidCountry: false
            });
        }
  
        return formIsValid;
    }

    componentDidMount() {
        const form = document.querySelector('.bx--form')
        let download = document.getElementById("download");

        if(this.cookie === '1') {
            download.style.display = 'block'
            form.parentElement.style.display = 'none'
        } else {
            download.style.display = 'none'
            form.parentElement.style.display = 'block'
        }

        form && form.addEventListener('submit', (event) => {
            event.preventDefault();
            this.setState(prevState => {
                return {
                    isSaving: !prevState.isSaving
                }
            });
            if (this.validateForm()) {
                const data = this.getFormData()
                fetch(this.apiEndpoint, { 
                    method: 'POST', 
                    body: JSON.stringify(data), 
                    headers: {'Content-Type': 'application/json'}
                })
                .then(() => {
                    document.cookie = this.eiuCookie + "=1";
                    form.parentElement.style.display = 'none'
                    download.style.display = 'block'

                    setTimeout(()=>{
                        this.setState(prevState=>{
                            return {
                                isSaving: !prevState.isSaving
                            }
                        })
                    }, 0)
                })
                .catch((err) => {
                    console.log(err)
                })
            } else {
                setTimeout(()=>{
                    this.setState(prevState=>{
                        return {
                            isSaving: !prevState.isSaving
                        }
                    })
                }, 0)
            }
        });

        const url = 'https://www.cloudflare.com/cdn-cgi/trace';
        axios
        .get(url)
        .then(async response => {
            let obj = {};
            for (let entry of response.data.split('\n')) {
                let pair = entry.split('=');
                obj[pair[0]] = pair[1];
            }
            // @ts-ignore
            this.setState({ userInfo: { ip: obj.ip, user_agent: obj.uag, city: obj.colo, country: obj.loc } });
        })
        .catch(error => console.log(error));
    }

    downloadReport = (event) => {
        event.preventDefault()
        fetch(this.props.name === 'eiu' ? eiu_file : eiu_precision_medicine_report_file)
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = this.props.name === 'eiu' ? 'New_Schools_of_Thought_Report.pdf' : 'EIU_Precision_Medicine_Report.pdf';
                a.click();
            });
        });
	}

    public render() {

        const countriesList = this.allCountries.map(function (country, i) {
            return <SelectItem text={country.label} value={country.value} key={i} />
        });

        return (
            <div>
                <div className={styles.formWrapper}>
                    <Form>
                        <FormGroup legendText={''}>
                            <TextInput invalid={this.state.invalidName} invalidText={this.props.intl.formatMessage({ id: "name_validation" })} id="name" labelText={this.props.intl.formatMessage({ id: "name" })} placeholder={this.props.intl.formatMessage({ id: "name" })} className="two-column" />
                            <TextInput invalid={this.state.invalidEmail} id="email" invalidText={this.props.intl.formatMessage({ id: "email_validation" })} labelText={this.props.intl.formatMessage({ id: "email" })} placeholder={this.props.intl.formatMessage({ id: "email" })} className="two-column" />
                        </FormGroup>
                        <FormGroup legendText={''}>
                            <TextInput invalid={this.state.invalidTitle} id="title" invalidText={this.props.intl.formatMessage({ id: "title_validation" })} labelText={this.props.intl.formatMessage({ id: "title" })} placeholder={this.props.intl.formatMessage({ id: "title" })} className="two-column" />
                            <TextInput invalid={this.state.invalidCompany} id="company" invalidText={this.props.intl.formatMessage({ id: "company_validation" })} labelText={this.props.intl.formatMessage({ id: "company" })} placeholder={this.props.intl.formatMessage({ id: "company" })} className="two-column" />
                        </FormGroup>
                        <FormGroup legendText={''}>
                            <Select invalid={this.state.invalidNationality} defaultValue="placeholder-item" id="nationality" invalidText={this.props.intl.formatMessage({ id: "select_validation" })} labelText={this.props.intl.formatMessage({ id: "nationality" })}>
                                { countriesList }
                            </Select>
                            <Select invalid={this.state.invalidCountry} defaultValue="placeholder-item" id="country" invalidText={this.props.intl.formatMessage({ id: "select_validation" })} labelText={this.props.intl.formatMessage({ id: "country" })} >
                                { countriesList }
                            </Select>
                        </FormGroup>
                        <FormGroup legendText={''}>
                            <Checkbox labelText={this.props.intl.formatMessage({ id: "subscribe_to_newsletter" }) } defaultChecked id="subscribe_to_newsletter" />
                        </FormGroup>
                        <Button disabled={this.state.isSaving} id="downloadBtn" type="submit" className={`text-style-body ${styles.ctaLink}`}>
                            { this.state.isSaving ? <FormattedMessage id="submiting" /> : <FormattedMessage id="download_report" /> }
                        </Button>
                    </Form>
                </div>
                <div id="download" className={styles.download_wrapper}>
                    <h2 className='text-style-h2'>
                        <FormattedMessage id="form_submit_thankyou" />
                    </h2>
                    <p className={`text-style-body ${styles.download_link}`}>
                        <a href="#" onClick={this.downloadReport}>
                            <FormattedMessage id="download_report_text" />
                        </a>
                    </p>
                </div>
            </div>
        );
    };
}

export default injectIntl(DownloadForm);