import React from "react";
import * as styles from './UIProcessResearchers.module.scss';
import useDarkMode from 'use-dark-mode';
//@ts-ignore
import ResearchersFlowchart from '../../assets/svgs/IDKT/researchers-flowchart.inline.svg'
//@ts-ignore
import ResearchersFlowchartDark from '../../assets/svgs/IDKT/researchers-flowchart-darkMode.inline.svg'
//@ts-ignore
import ResearchersFlowchartMobile from '../../assets/svgs/IDKT/researchers-flowchart-mobile.inline.svg'
//@ts-ignore
import ResearchersFlowchartMobileDark from '../../assets/svgs/IDKT/researchers-flowchart-mobile-darkMode.inline.svg'
//@ts-ignore
import ResearchersFlowchartArabic from '../../assets/svgs/IDKT/research-flowchart-arabic.inline.svg'
//@ts-ignore
import ResearchersFlowchartArabicDark from '../../assets/svgs/IDKT/research-flowchart-darkMode-arabic.inline.svg'
//@ts-ignore
import ResearchersFlowchartArabicMobile from '../../assets/svgs/IDKT/research-flowchart-mobile-arabic.inline.svg'
//@ts-ignore
import ResearchersFlowchartArabicMobileDark from '../../assets/svgs/IDKT/research-flowchart-mobile-arabic-darkMode.inline.svg'
import UIPopupOver from '../UIPopupOver';
//import $ from 'jquery'

export const UIProcessResearchers = (currLang?: any) => {
  const darkMode = useDarkMode(false);
  const isMobile = (typeof window !== 'undefined') && window.innerWidth <= 768;

  var infoGraphics = null

  if(currLang.currLang === 'ar-QA') {
    if(isMobile) {
      if(darkMode.value) {
        infoGraphics = <ResearchersFlowchartArabicMobileDark />
      } else {
        infoGraphics = <ResearchersFlowchartArabicMobile />
      }
    } else {
      if(darkMode.value) {
        infoGraphics = <ResearchersFlowchartArabicDark />
      } else {
        infoGraphics = <ResearchersFlowchartArabic />
      }
    }
  } else {
    if(isMobile) {
      if(darkMode.value) {
        infoGraphics = <ResearchersFlowchartMobileDark />
      } else {
        infoGraphics = <ResearchersFlowchartMobile />
      }
    } else {
      if(darkMode.value) {
        infoGraphics = <ResearchersFlowchartDark />
      } else {
        infoGraphics = <ResearchersFlowchart />
      }
    }
  }

  return (
    <div className={`${styles.UIImageCircle}`}>
        <div id='chart' className={styles.circleContainer}>
          { infoGraphics }
        </div>
        {
          <UIPopupOver type='for-researchers' />
        }
    </div>
  );
}

