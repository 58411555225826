import React from "react";
import * as styles from './UIProcess.module.scss';
import useDarkMode from 'use-dark-mode';
//@ts-ignore
import EntrepreneursFlowchart from '../../assets/svgs/IDKT/entrepreneurs-flowchart.inline.svg'
//@ts-ignore
import EntrepreneursFlowchartDark from '../../assets/svgs/IDKT/entrepreneurs-flowchart-darkMode.inline.svg'
//@ts-ignore
import EntrepreneursFlowchartMobile from '../../assets/svgs/IDKT/entrepreneurs-flowchart-mobile.inline.svg'
//@ts-ignore
import EntrepreneursFlowchartMobileDark from '../../assets/svgs/IDKT/entrepreneurs-flowchart-mobile-darkMode.inline.svg'
//@ts-ignore
import EntrepreneursFlowchartArabic from '../../assets/svgs/IDKT/entrepreneurs-flowchart-arabic.inline.svg'
//@ts-ignore
import EntrepreneursFlowchartArabicDark from '../../assets/svgs/IDKT/entrepreneurs-darkMode-flowchart-arabic.inline.svg'
//@ts-ignore
import EntrepreneursFlowchartArabicMobile from '../../assets/svgs/IDKT/entrepreneurs-flowchart-mobile-arabic.inline.svg'
//@ts-ignore
import EntrepreneursFlowchartArabicMobileDark from '../../assets/svgs/IDKT/entrepreneurs-flowchart-mobile-arabic-darkMode.inline.svg'
import UIPopupOver from '../UIPopupOver';
//import $ from 'jquery'

export const UIProcessEntrepreneurs = (currLang?: any) => {
  const darkMode = useDarkMode(false);
  const isMobile = (typeof window !== 'undefined') && window.innerWidth <= 768;

  var infoGraphics = null

  if(currLang.currLang === 'ar-QA') {
    if(isMobile) {
      if(darkMode.value) {
        infoGraphics = <EntrepreneursFlowchartArabicMobileDark />
      } else {
        infoGraphics = <EntrepreneursFlowchartArabicMobile />
      }
    } else {
      if(darkMode.value) {
        infoGraphics = <EntrepreneursFlowchartArabicDark />
      } else {
        infoGraphics = <EntrepreneursFlowchartArabic />
      }
    }
  } else {
    if(isMobile) {
      if(darkMode.value) {
        infoGraphics = <EntrepreneursFlowchartMobileDark />
      } else {
        infoGraphics = <EntrepreneursFlowchartMobile />
      }
    } else {
      if(darkMode.value) {
        infoGraphics = <EntrepreneursFlowchartDark />
      } else {
        infoGraphics = <EntrepreneursFlowchart />
      }
    }
  }

  return (
    <div className={`${styles.UIImageCircle}`}>
        <div id='chart' className={styles.circleContainer}>
          { infoGraphics }
        </div>
        {
          <UIPopupOver type='for-entrepreneurs' />
        }
    </div>
  );
}

