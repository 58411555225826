import * as React from 'react';
import * as styles from './PageShared.module.scss';
import { PageVerticalQuery } from '../gatsby-queries';
import PageWrapper, { IPageProps } from './PageWrapper';
import { FormattedMessage } from 'react-intl';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { Sine, gsap } from 'gsap/dist/gsap.min';
import ModulesWrapper from '../components/ModulesWrapper';
import { FixedTopSectionNav } from '../ui/FixedTopSectionNav';
import { zeroPad } from '../utils/StringUtils';
import { Helmet } from 'react-helmet';
import { GatsbyImageWrapper } from '../components/ui/GatsbyImageWrapper';
import { graphql } from 'gatsby';
//import JuicerFeed from '../lib/juicer-feed/juicer-feed';
import { ModuleDynamicList } from '../components/ModuleDynamicList';
import { PressListItem } from '../components/ListItems/PressListItem';
import { getPagePath } from '../utils/URLHelper';
import DownloadForm from '../ui/UIDownloadForm';
import { UIImageCircle } from '../ui/IDKT/UIImageCircle';
import { UIProcess } from '../ui/IDKT/UIProcess';
import { UIProcessResearchers } from '../ui/IDKT/UIProcessResearchers';
import { UIProcessEntrepreneurs } from '../ui/IDKT/UIProcessEntrepreneurs';
import ModuleQFAlumniPortalSignup from '../components/ModuleQFAlumniPortalSignup';
import NewsList from '../components/NewsList';
//import UIArtContestForm from '../ui/PPF/UIArtContestForm';
//import UIECSSForm from '../ui/UIECSSForm';

interface Props extends IPageProps {
	data: PageVerticalQuery;
}

class PageVertical extends React.Component<Props> {

	componentDidMount() {
		gsap.registerPlugin(ScrollToPlugin);
		if (this.props.data?.contentfulPageVertical.slug === "idkt/technologies") {
			const script = document.createElement('script')
			script.async = true
			script.defer = true
			script.src = 'https://qf.flintbox.com/embed.js'
			document.querySelector('div#flintbox-embed').appendChild(script)
		}
		if (this.props.data.contentfulPageVertical.slug === "25-years-of-qatar-foundation") {
			const iframe = document.createElement('iframe')
			iframe.src = this.props.pathContext.languageCode === 'ar-QA' ? 'https://embed.mindstamp.io/embed/uQWHyavO' : 'https://embed.mindstamp.io/embed/aPahmSRa'
			iframe.width = '900'
			iframe.height = '600'
			iframe.className = 'iv-video'
			iframe.allowFullscreen = true
			iframe.frameBorder = "0"
			document.querySelector('div#interactive-video-embed').appendChild(iframe)
		}
		if (this.props.data?.contentfulPageVertical.slug === 'eiu') {
			var wrapper = document.getElementById("section-2");
			var form = document.querySelector(".bx--form").parentElement;
			var download = document.getElementById("download");
			var download_access_cookie = typeof document !== 'undefined' && document.cookie
			var cookie = ("; "+ download_access_cookie).split("; download_access_granted=").pop().split(";").shift();
			if(cookie === '1') {
				form.parentElement.style.display = 'none'
				download.style.display = 'block'
				wrapper.appendChild(download);
			} else {
				wrapper.appendChild(form);
				wrapper.appendChild(download);
			}
		}

		if (this.props.data?.contentfulPageVertical.slug === 'idkt/for-industry' || this.props.data?.contentfulPageVertical.slug === 'idkt/for-researchers' || this.props.data?.contentfulPageVertical.slug === 'idkt/for-entrepreneurs') {
			var wrapper = document.getElementById("section-2");
			var chart = document.getElementById("chart");

			wrapper.appendChild(chart);
		}

		if (this.props.data.contentfulPageVertical.slug === 'students-of-change') {
			var yocWrapper = document.getElementById("youth-of-change");
			var iocWrapper = document.getElementById("innovators-of-change");
			var pocWrapper = document.getElementById("partners-of-change");

			let allyouthOfChangeArticles = document.getElementById("youth-of-change-articles")
			let allInnovatorsOfChangeArticles = document.getElementById("innovators-of-change-articles")
			let allPartnersOfChangeArticles = document.getElementById("partners-of-change-articles")

			yocWrapper.appendChild(allyouthOfChangeArticles)
			iocWrapper.appendChild(allInnovatorsOfChangeArticles)
			pocWrapper.appendChild(allPartnersOfChangeArticles)
		}

		/**
		if (this.props.data.contentfulPageVertical.slug === 'idkt/about') {
			var wrapper = document.getElementById("section-4");
			var dynamicModule = document.getElementById("dynamic-module");

			wrapper && wrapper.appendChild(dynamicModule);
		}

		if (this.props.data.contentfulPageVertical.slug === 'ppf-art-contest') {
			var wrapper = document.getElementById("section-4");
			var dynamicModule = document.getElementById("ppf-art-contest-form");

			wrapper.appendChild(dynamicModule);
		}
		
		if (this.props.data.contentfulPageVertical.slug === 'education-city-speaker-series') {

			var gotoForm = function() {
				gsap.to(window, { scrollTo: { y: '#form_ecss', offsetY: 0, autoKill: false }, ease: Sine.easeInOut });
			};
			  
			var OneClick = document.getElementById("goto_form");
			OneClick.addEventListener('click', gotoForm, false);

			var wrapper = document.getElementById("ecss_form");
			var form = document.querySelector(".bx--form").parentElement;
			var download = document.getElementById("download");
			var download_access_cookie = typeof document !== 'undefined' && document.cookie
			var cookie = ("; "+ download_access_cookie).split("; crafting_lifetime_of_change_from_month_of_reflection_1=").pop().split(";").shift();
			if(cookie === '1') {
				form.parentElement.style.display = 'none'
				download.style.display = 'block'
				wrapper.prepend(download);
			} else {
				wrapper.prepend(form);
				wrapper.prepend(download);
			}
		}
		**/
		
		if(window.location.hash) {
			setTimeout(() => {
				this.scrollToHandler(this)
			}, 1000);
		}
	}

	private scrollToHandler = event => {
		let hash;
		let offsetY = 0
		if(window.location.hash.substring(1)) {
			hash = '#' + window.location.hash.substring(1);
		} else {
			hash = event.target.getAttribute('href')
			event.preventDefault()
		}

		if (hash === '#formdone') {
			return;
		}
		if (event) {
			gsap.to(window, { scrollTo: { y: hash, offsetY: offsetY, autoKill: false }, ease: Sine.easeInOut });
		}
	};

	private getTopNavSection(sections: any[]) {
		return (
			<div data-swiftype-index="false" className={styles.topSectionNav}>
				<h6 className={`text-style-quoted-by ${styles.sectionTitle}`}>
					<FormattedMessage id="Sections" />
				</h6>
				<ul>
					{sections.map((module, index) => {
						return (
							<li key={module.id}>
								<a onClick={this.scrollToHandler} className={`text-style-link-1 ${styles.sectionLink}`} href={`#section-${index + 1}`}>
									{zeroPad(index + 1)}. {module.title}
								</a>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}

	render() {
		if (!this.props.data) {
			return <h1 style={{'marginTop': '100px'}}>ERROR</h1>;
		}
		const pageData = this.props.data.contentfulPageVertical;
		//@ts-ignore:
		const pressReleaseData = this.props.data.allPR;
		//@ts-ignore:
		const idktPressReleaseData = this.props.data.idktPR;
		let modules = [];
		if (pageData.modulesWrapper) {
			pageData.modulesWrapper.forEach(moduleWrapper => modules.push(...moduleWrapper.modules));
		}
		let sections = [];
		modules.map(module => {
			if (module.__typename === 'ContentfulModuleSectionIntroduction' && !module.hideSectionNumber) {
				sections.push(module);
			}
		});

		const animationDirection = this.props.pathContext.languageCode === 'ar-QA' ? -1 : 1;

		let allyouthOfChangeArticles = [];
		let allInnovatorsOfChangeArticles = [];
		let allPartnersOfChangeArticles = [];

		this.props.data.youthOfChange.edges.forEach(item => {
			allyouthOfChangeArticles.push(item.node)
		});

		this.props.data.innovatorsOfChange.edges.forEach(item => {
			allInnovatorsOfChangeArticles.push(item.node)
		});

		this.props.data.partnersOfChange.edges.forEach(item => {
			allPartnersOfChangeArticles.push(item.node)
		});

		const interactiveVideoTeaserImage = this.props.pathContext.languageCode === 'ar-QA' ? 'https://images.ctfassets.net/2h1qowfuxkq7/1AJYPj2ZhI4MxdvsgHGyGV/458b1fab5dbb4ca37968b1dccb263e1d/IQ6.2.png' : 'https://images.ctfassets.net/2h1qowfuxkq7/1AJYPj2ZhI4MxdvsgHGyGV/66031fa4a1ebcb89166d64a2238a3cdc/IQ5.2__2_.png'

		return (
			//@ts-ignore:
			<PageWrapper location={this.props.location} pageData={pageData} type={'vertical'} title={pageData.title} metaTitle={pageData.metaTitle} pathContext={this.props.pathContext}>
				<div className="container pagePaddingTop">

					<Helmet>
						{
						//@ts-ignore:
						pageData.metaDescription && ( <meta name="description" content={pageData.metaDescription.metaDescription} /> )}
						<meta className="swiftype" name="preview_image" data-type="enum" content={pageData.heroImage && pageData.heroImage.file.url} />
						<meta className="swiftype" name="type" data-type="enum" content="page" />
					</Helmet>
					{
						this.props.data.contentfulPageVertical.slug === "25-years-of-qatar-foundation" && (
							<Helmet>
								<meta property="og:image" content={interactiveVideoTeaserImage} />
								<meta name="twitter:image" content={interactiveVideoTeaserImage} />
							</Helmet>
						)
					}
					{sections.length > 0 && <FixedTopSectionNav sections={sections} />}
						<div className={styles.topSection}>
							{
								// @ts-ignore
								<div className={`${pageData.fullWidthLayout ? 'col-md-12' : 'col-md-6 col-xl-4'} ${styles.topSectionText}`}>
									{
										<h1 className={`text-style-h1 ${pageData.fullWidthLayout && 'max-width'}`}>{pageData.headline && pageData.headline.headline}</h1>
									}
									{
										// @ts-ignore
										<p className={`text-style-body ${styles.subtitle} ${pageData.fullWidthLayout ? 'text-style-introduction' : ''}`} dangerouslySetInnerHTML={{ __html: pageData.subtitle && pageData.subtitle.subtitle }} />
									}
								</div>
							}
							{sections.length > 0 && this.getTopNavSection(sections)}
						</div>
						{
						// @ts-ignore
						<GatsbyImageWrapper alt={pageData.heroImage && pageData.heroImage.title} outerWrapperClassName={`w-100 ${styles.heroImage}`} fluid={pageData.heroImage && pageData.heroImage.sizes} />
						}
					<ModulesWrapper
						languageCode={this.props.pathContext.languageCode}
						programsListData={this.props.data.allContentfulPageProgram}
						upcomingEventsData={this.props.pathContext.upcomingEvents}
						hasHeroImage={true}
						data={pageData.modulesWrapper}
						slug={pageData.slug}
					>
						{pageData.slug == 'media-center' && pressReleaseData && (
							<ModuleDynamicList position={21} count={pressReleaseData.edges.length} title={<FormattedMessage id="Press Releases" />}>
								<ul>
									{pressReleaseData.edges.map((edge, index) => {
										return <PressListItem title={edge.node.title} date={edge.node.date} url={getPagePath(edge.node.slug, 'press')} key={index} />;
									})}
								</ul>
							</ModuleDynamicList>
						)}
					</ModulesWrapper>
				</div>
				{
					/** 
					pageData.slug == 'convocation' && (
					<div className="module-margin">
						<JuicerFeed feedId="agatabraja" />
					</div>)
					*/
				}
				{pageData.slug === 'eiu' && (
					<DownloadForm name='eiu' currLang={this.props.pathContext.languageCode} />
				)}
				{pageData.slug === 'eiu/precision-medicine-report' && (
					<DownloadForm name='precision-medicine-report' currLang={this.props.pathContext.languageCode} />
				)}
				{pageData.slug == 'idkt' && (
					<div className="module-margin">
						<UIImageCircle currLang={this.props.pathContext.languageCode} />
					</div>
				)}
				{pageData.slug == 'idkt/for-industry' && (
					<div className="module-margin">
						<UIProcess currLang={this.props.pathContext.languageCode} />
					</div>
				)}
				{pageData.slug == 'idkt/for-researchers' && (
					<div className="module-margin">
						<UIProcessResearchers  currLang={this.props.pathContext.languageCode} />
					</div>
				)}
				{pageData.slug == 'idkt/for-entrepreneurs' && (
					<div className="module-margin">
						<UIProcessEntrepreneurs currLang={this.props.pathContext.languageCode} />
					</div>
				)}
				{
				pageData.slug === 'education/alumni-program' && (
					<ModuleQFAlumniPortalSignup />
				)					
				/** 
				pageData.slug === 'ppf-art-contest' && (
					<UIArtContestForm currLang={this.props.pathContext.languageCode} />
				)
				}
				{
				pageData.slug === 'education-city-speaker-series' && (
					<UIECSSForm currLang={this.props.pathContext.languageCode} />
				)
				*/
				}
				{
					pageData.slug === 'students-of-change' && (
						<>
							<NewsList
								articles={allyouthOfChangeArticles}
								languageCode={this.props.location.pathname === '/ar/' ? 'ar-QA' : 'en-US'}
								animationDirection={animationDirection}
								title={this.props.pathContext.languageCode === 'ar-QA' ? 'أبرز الفعاليات' : 'Featured Events'}
								id='youth-of-change-articles'
								introText={<FormattedMessage id="youthOfChange_intro" />}
								type="stories"
							/>
							<NewsList
								articles={allInnovatorsOfChangeArticles}
								languageCode={this.props.location.pathname === '/ar/' ? 'ar-QA' : 'en-US'}
								animationDirection={animationDirection}
								title={this.props.pathContext.languageCode === 'ar-QA' ? 'أبرز الفعاليات' : 'Featured Events'}
								id='innovators-of-change-articles'
								introText={<FormattedMessage id="innovatorsOfChange_intro" />}
								type="stories"
							/>
							<NewsList
								articles={allPartnersOfChangeArticles}
								languageCode={this.props.location.pathname === '/ar/' ? 'ar-QA' : 'en-US'}
								animationDirection={animationDirection}
								title={this.props.pathContext.languageCode === 'ar-QA' ? 'أبرز الفعاليات' : 'Featured Events'}
								id='partners-of-change-articles'
								introText={<FormattedMessage id="partnersOfChange_intro" />}
								type="stories"
							/>
						</>	
					)
				}
			</PageWrapper>
		);
	}
}

export default PageVertical;

export const pageQuery = graphql`
	query PageVerticalQuery($id: String, $languageCode: String) {
		contentfulPageVertical(contentful_id: { eq: $id }, node_locale: { eq: $languageCode }) {
			id
			slug
			title
			headline {
				headline
			}
			subtitle {
				subtitle
			}
			metaTitle
			metaDescription {
				metaDescription
			}
			heroImage {
				title
				file {
					url
				}
				sizes(maxWidth: 1680, maxHeight: 700, resizingBehavior: FILL, quality: 85) {
					...GatsbyContentfulSizes_withWebp_noBase64
				}
			}
			modulesWrapper {
				...ContentfulModuleWrapperFragment
			}
			fullWidthLayout
		}
		allContentfulPageProgram(filter: { node_locale: { eq: $languageCode }, title: { ne: "WORKAROUND. DO NOT DELETE." } }, sort: { order: ASC, fields: [title] }) {
			edges {
				node {
					slug
					title
					filterEntity {
						title
						contentful_id
					}
					filterProgramType {
						title
						contentful_id
					}
				}
			}
		}
		youthOfChange: allContentfulPageArticle(filter: { contentful_id: {in: [ "6eZmyy0Aisc1NWb4x5FSei", "2Q401JVcg8BPZqYUY6PKGg", "NqI6yKSn5ni0asQMFpofg", "709DcAYqOcQEnKvACqbmOb", "3phKIJQfPbEiRUJQTvoijO", "5TfJPepraMGw17bWNOAAty", "2vU9HuTNqGTKVBlemg6FHH" ]}, node_locale: { eq: $languageCode }}, sort: { order: DESC, fields: [date] }, limit: 7) {
			edges {
				node {
					id
					title
					contentful_id
					node_locale
					slug
					filterVerticalCategory {
						title
					}
					teaserImage {
						title
						sizes(maxWidth: 960, quality: 85) {
							...GatsbyContentfulSizes_withWebp_noBase64
						}
					}
				}
			}
		}
		innovatorsOfChange: allContentfulPageArticle(filter: { contentful_id: {in: [ "7n8t5lWjjybRJfCahkMSis", "5mxGZhc9Yp3SAqDw2UG0E7", "3WXKaISYJp28LTT6Q05Y4A", "1a6riBmU2dXiVIbOEPW6nC1a6riBmU2dXiVIbOEPW6nC", "4W8oDTuhJ55aT82MtMLuaG", "3ID2eUZqOCjGLvywVgv7CW" ]}, node_locale: { eq: $languageCode }}, sort: { order: DESC, fields: [date] }, limit: 7) {
			edges {
				node {
					id
					title
					contentful_id
					node_locale
					slug
					filterVerticalCategory {
						title
					}
					teaserImage {
						title
						sizes(maxWidth: 960, quality: 85) {
							...GatsbyContentfulSizes_withWebp_noBase64
						}
					}
				}
			}
		}
		partnersOfChange: allContentfulPageArticle(filter: { contentful_id: {in: [ "5Hpavf1ejX2hWsNiPe66AJ", "4VUs1vLlHKnUJUzCHCbaj5", "NBml5Xb50z0ZkglwajKlU", "4DvwFaSRbXs35pJLk0ssXm", "3l4kWJE1eMJX06TIYU8CiC", "6WW9EFjCjz2JqoypXogjkt" ]}, node_locale: { eq: $languageCode }}, sort: { order: DESC, fields: [date] }, limit: 7) {
			edges {
				node {
					id
					title
					contentful_id
					node_locale
					slug
					filterVerticalCategory {
						title
					}
					teaserImage {
						title
						sizes(maxWidth: 960, quality: 85) {
							...GatsbyContentfulSizes_withWebp_noBase64
						}
					}
				}
			}
		}
		allPR: allContentfulPagePressRelease(filter: { node_locale: { eq: $languageCode }, title: { ne: "WORKAROUND. DO NOT DELETE." } }, limit: 10, sort: { order: DESC, fields: [date] }) {
			edges {
				node {
					title
					slug
					date
				}
			}
		}

		idktPR: allContentfulPagePressRelease(filter: { node_locale: { eq: $languageCode }, filterTags: {elemMatch: {title: {eq: "IDKT"}}}}, limit: 10, sort: { order: DESC, fields: [date] }) {
			edges {
			  node {
				title
				slug
				date
			  }
			}
		}
	}
`;
